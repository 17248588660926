<template>
  <div class="autocomplete">
    <label class="autocomplete__label" for="autocomplete__input">
      {{ $t("address.placeholder") }}
    </label>
    <div id="autocomplete__container" ref="autocompleteContainer"></div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import "accessible-autocomplete/dist/accessible-autocomplete.min.css";
import { isHeadless } from "@/helpers";

export default {
  props: ["modelValue", "format", "getResults", "setFocus"],
  emits: ["update:modelValue"],
  compatConfig: { COMPONENT_V_MODEL: false },
  setup(props, { emit }) {
    const autocompleteContainer = ref();
    let autocomplete;
    const { t } = useI18n({});

    onMounted(async () => {
      if (isHeadless()) {
        return;
      }
      const { default: accessibleAutocomplete } = await import(
        "accessible-autocomplete"
      );
      autocomplete = accessibleAutocomplete({
        autoselect: false,
        element: autocompleteContainer.value,
        id: "autocomplete__input",
        templates: {
          inputValue: props.format,
          suggestion: props.format
        },
        source: (query, populateResults) => {
          props.getResults(query, populateResults);
        },
        placeholder: t("address.placeholder"),
        displayMenu: "overlay",
        showNoOptionsFound: false,
        confirmOnBlur: true,
        onConfirm: confirmed => {
          document.activeElement.blur();
          emit("update:modelValue", confirmed);
        }
      });
      if (props.setFocus) {
        document.querySelector("#autocomplete__input").focus();
      }
    });

    return {
      autocompleteContainer,
      autocomplete
    };
  }
};
</script>